<template>
    <div>
        <div>
            <v-col cols="12" sm="4">
                <h4>Editar Paciente</h4>
            </v-col>
        </div>
        <v-expansion-panels v-model="panel" multiple focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Datos Personales</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-text-field class="styled-input" v-model="currentPaciente.nombre" clearable type="text"
                        label="Nombre"></v-text-field>
                    <v-text-field v-model="currentPaciente.apellido" clearable type="text" label="Apellido"></v-text-field>
                    <v-text-field v-model="currentPaciente.documento" counter="8" clearable type="number"
                        label="Documento"></v-text-field>
                    <v-text-field v-model="currentPaciente.telefono" clearable type="number"
                        label="Telefono"></v-text-field>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Datos Medicos</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!--TODO , Regex para strings: ^[a-zA-Z ]*$ Implementar! 'No se permiten numeros'-->
                    <v-combobox v-model="currentPaciente.mutualista" clearable :items="mutualistas"
                        label="Seleccione Mutualista" auto-select-first single hide-selected
                        @input="searchInputMutualista = null" :search-input.sync="searchInputMutualista"></v-combobox>

                    <v-combobox v-model="currentPaciente.emergenciamovil" :items="emergencias" 
                        label="Seleccione Emergencia" auto-select-first single hide-selected 
                        @input="searchInputEmergencia = null" :search-input.sync="searchInputEmergencia"></v-combobox>

                    <v-combobox v-model="currentPaciente.patologiascronicas" :items="patologias" item-text="nombrePatologia"
                        item-value="id" data-vv-name="patologias" label="Patologias Cronicas" auto-select-first chips
                        small-chips deletable-chips hide-selected multiple @input="searchInputPatologia = null"
                        :search-input.sync="searchInputPatologia"></v-combobox>

                    <v-text-field v-model="currentPaciente.antecedentesdiabeticos" clearable type="text"
                        label="Antecedentes Diabeticos"></v-text-field>
                    <v-text-field v-model="currentPaciente.medicamentosdiarios" clearable type="text"
                        label="Medicamentos Diarios"></v-text-field>
                    <v-text-field v-model="currentPaciente.alergiamedicamentos" clearable type="text"
                        label="Alergias a Medicamentos"></v-text-field>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
                <v-expansion-panel-header>Actividad Fisica & Autorizacion</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!--TODO , Regex para strings: ^[a-zA-Z ]*$ Implementar! 'No se permiten numeros'-->
                    <v-text-field v-model="currentPaciente.formadetrabajo" clearable type="text"
                        label="Forma de Trabajo"></v-text-field>
                    <v-text-field v-model="currentPaciente.actividadfisicatipo" clearable type="text"
                        label="Tipo de Actividad Fisica"></v-text-field>
                    <v-card-actions class="pa-4">
                        Frecuencia de Actividad Fisica:
                        <v-spacer></v-spacer>
                        <v-rating v-model="currentPaciente.actividadfisicafrecuencia" empty-icon="mdi-heart-outline"
                            full-icon="mdi-heart" half-icon="mdi-heart-half" half-increments hover length="6"
                            size="20"></v-rating>
                    </v-card-actions>
                    <v-checkbox class="align-center justify-center" label="Autoriza uso de material"
                        v-model="currentPaciente.materialautorizado"></v-checkbox>
                    <v-checkbox class="align-center justify-center" label="El paciente se encuentra activo"
                        v-model="currentPaciente.pacienteactivo"></v-checkbox>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <div class="text-center d-flex mt-3 pb-4">
                <v-btn class="ml-1" width="auto" color="primary" @click="
                    updatePaciente();
                alert = true;
                closeExpansions(), (snackbar = true);
                                                  ">
                    Actualizar
                </v-btn>
                <v-btn class="ml-1" width="auto" color="secondary" @click="goToList">
                    Volver al Listado
                </v-btn>
                <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
            </div>
        </v-expansion-panels>

        <v-snackbar v-model="snackbar" :multi-line="multiLine">
            Actualizado correctamente a las {{ showEditedTime() }}
            <template v-slot:action="{ attrs }">
                <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import PacienteDataService from "../services/PacienteDataService";
import PatologiaDataService from "../services/PatologiaDataService";
import EmergenciaDataService from "../services/EmergenciaDataService";
import MutualistaDataService from "../services/MutualistaDataService";
import ConfirmDialogue from "./ConfirmDialogue.vue";
export default {
    name: "paciente",
    components: {
        ConfirmDialogue,
    },
    data() {
        return {
            multiLine: true,
            snackbar: false,
            panel: [],
            items: 3,
            alert: false,
            currentPaciente: {
                id: null,
                nombre: "",
                apellido: "",
                telefono: "",
                documento: "",
                mutualista: "",
                emergenciamovil: "",
                antecedentesdiabeticos: "",
                patologiascronicas: "",
                medicamentosdiarios: "",
                alergiamedicamentos: "",
                formadetrabajo: "",
                actividadfisicatipo: "",
                actividadfisicafrecuencia: 0,
                materialautorizado: false,
                pacienteactivo: false,
            },
            mutualistas: null,
            emergencias: null,
            patologias: null,
            searchInputMutualista: null,
            searchInputEmergencia: null,
            searchInputPatologia: null,
        };
    },

    methods: {
        async goToList() {
            const ok = await this.$refs.confirmDialogue.show({
                title: "Volver al listado?",
                message: "Todos los cambios se perderan.",
                okButton: "Volver",
            });
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.$router.push("/pacientes");
            } else {
                // Doing nothing now, can throw an alert() too
            }
        },
        getRequestParams(searchWhat, page, pageSize) {
            let params = {};
            if (searchWhat) {
                params["nombre"] = searchWhat;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["size"] = pageSize;
            }
            return params;
        },
        retrieveMutualistas() {
            const params = this.getRequestParams("", 1, 24);
            MutualistaDataService.getAll(params)
                .then((response) => {
                    const { mutualistas, totalPages } = response.data;
                    this.mutualistas = mutualistas.map(this.getDisplayMutualista);
                    const arr = [];
                    this.mutualistas.forEach((object) => {
                        arr.push(object.nombreMutualista);
                    });
                    this.mutualistas = arr;
                    this.totalPages = totalPages;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getDisplayMutualista(mutualista) {
            return {
                nombreMutualista: mutualista.nombre,
            };
        },
        retrievePatologias() {
            const params = this.getRequestParams("", 1, 24);
            PatologiaDataService.getAll(params)
                .then((response) => {
                    const { patologias, totalPages } = response.data;
                    this.patologias = patologias.map(this.getDisplayPatologia);
                    const arr = [];
                    this.patologias.forEach((object) => {
                        arr.push(object.nombrePatologia);
                    });
                    this.patologias = arr;
                    this.totalPages = totalPages;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getDisplayPatologia(patologia) {
            return {
                nombrePatologia: patologia.nombre,
            };
        },

        retrieveEmergencias() {
            const params = this.getRequestParams("", 1, 24);
            EmergenciaDataService.getAll(params)
                .then((response) => {
                    const { emergencias, totalPages } = response.data;
                    console.log("retrieveEmergencias()");
                    console.log(response.data);
                    this.emergencia = emergencias.map(this.getDisplayEmergencia);
                    const arr = [];
                    this.emergencia.forEach((object) => {
                        arr.push(object.nombreEmergencia);
                    });
                    this.emergencias = arr;
                    this.totalPages = totalPages;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getDisplayEmergencia(emergencia) {
            return {
                nombreEmergencia: emergencia.nombre,
            };
        },
        showEditedTime() {
            var editedTime = new Date();
            editedTime = editedTime.toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            });
            return editedTime;
        },

        closeExpansions() {
            this.panel = [];
        },
        getPaciente(id) {
            PacienteDataService.get(id)
                .then((response) => {
                    this.currentPaciente = response.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        updateAuthorization(authmaterial) {
            var data = {
                id: this.currentPaciente.id,
                materialautorizado: authmaterial,
            };

            PacienteDataService.update(this.currentPaciente.id, data)
                .then((response) => {
                    this.currentPaciente.materialautorizado = authmaterial;
                    console.log(response.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        deactivatePaciente(patientstatus) {
            var data = {
                id: this.currentPaciente.id,
                pacienteactivo: patientstatus,
            };

            PacienteDataService.update(this.currentPaciente.id, data)
                .then((response) => {
                    this.currentPaciente.pacienteactivo = patientstatus;
                    console.log(response.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        updatePaciente() {
            PacienteDataService.update(this.currentPaciente.id, this.currentPaciente)
                .then((response) => {
                    console.log("updatePaciente() Response data: " + response.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        deletePaciente() {
            PacienteDataService.delete(this.currentPaciente.id)
                .then((response) => {
                    console.log("deletePaciente() Response data: " + response.data);
                    this.$router.push({
                        name: "pacientes",
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
    mounted() {
        this.retrievePatologias();
        this.retrieveMutualistas();
        this.retrieveEmergencias();
        this.getPaciente(this.$route.params.id);
    },
};
</script>

<style>
/* Hide up and down buttons on numeric type fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
