import http from "../http-common";

class EmergenciaDataService {
  getAll(params) {
    return http.get("/emergencia", {
      params,
    });
  }
  get(id) {
    return http.get(`/emergencia/${id}`);
  }
  create(data) {
    return http.post("/emergencia", data);
  }
  update(id, data) {
    return http.put(`/emergencia/${id}`, data);
  }
  delete(id) {
    return http.delete(`/emergencia/${id}`);
  }
}

export default new EmergenciaDataService();